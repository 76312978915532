.team-section {
  padding: 40px;
  text-align: center;
}

.team-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.team-members {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensures all team members align to the same height */
  gap: 20px;
}

.team-member {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f31212;
  border: solid 4px black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex-grow: 1; /* Allows all team-member elements to grow equally */
}

.member-image-wrapper {
  border-radius: 50%;
  overflow: hidden;
  width: 220px;
  height: 275px;
  margin: 0 auto 20px;
  border: 4px solid black;
}

.member-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Prevents distortion */
}

.member-name {
  color: white;
  font-size: 1.8rem;
  margin: 10px 0;
}

.member-position {
  font-size: 1.4rem;
  color: white;
}

.member-description {
  font-size: 1rem;
  color: white;
  margin-top: 10px;
  text-align: justify;
  padding: 10px 0;
  line-height: 1.6;
  flex-grow: 1; /* Makes the description take available space while keeping height consistent */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .team-members {
    flex-direction: column; /* Stack team members on smaller screens */
    align-items: center;
  }

  .team-member {
    width: 100%; /* Make the team member card take full width on smaller screens */
    max-width: 350px;
  }

  .member-image-wrapper {
    width: 200px;
    height: 250px;
  }
}
