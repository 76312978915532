/* Navbar Container */
.navbar {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Top Section */
.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
}

.company-logo {
  max-height: 180px;
  width: auto;
}

.company-name {
  display: inline-block;
  background-color: #f31212;
  padding: 20px 40px;
  border-radius: 10px;
  text-align: center;
  border: 2px solid black;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.company-name h1 {
  font-family: 'Georgia', serif;
  color: white;
  font-size: 2rem;
  margin: 0;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

/* Contact Info */
.contact-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  font-weight: bolder;
  font-size: 1.2em;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
}

.contact-icon {
  color: #333;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

/* Apply Inter font specifically to the contact links */
.contact-item a {
  font-family: "Inter", sans-serif; /* Use Inter font */
  font-weight: 400;
  font-style: normal;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-item a:hover,
.contact-icon:hover {
  color: #f31212;
}


/* Navigation Menu */
.nav-menu {
  background: #f31212;
  width: 100%;
  padding: 5px 0;
  text-align: center;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
}

.nav-menu ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  gap: 20px;
}

.nav-menu ul li {
  position: relative;
}

.nav-menu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: x-large;
  font-weight: bold;
  padding: 8px 12px;
  display: block;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Underline Animation for Hover */
.nav-menu ul li a::before,
.nav-menu ul li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: transform 0.4s ease;
  transform: scaleX(0);
}

.nav-menu ul li a::before {
  top: 0;
  left: 0;
  transform-origin: left;
}

.nav-menu ul li a::after {
  bottom: 0;
  right: 0;
  transform-origin: right;
}

.nav-menu ul li a:hover::before {
  transform: scaleX(1);
}

.nav-menu ul li a:hover::after {
  transform: scaleX(1);
}

.nav-menu ul li a:hover {
  color: black;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .top-section {
    flex-direction: column;
    gap: 15px;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 5px;
  }

  .nav-menu ul li {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .company-logo {
    max-height: 80px;
  }

  .company-name h1 {
    font-size: 1.2rem;
  }

  .contact-info {
    font-size: 1em;
    gap: 5px;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
  }

  .nav-menu ul li a {
    font-size: 1rem;
    padding: 10px;
  }
}
