.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
}

.logo-container {
  position: relative;
  z-index: 2;
}

.logo {
  width: 200px;
  height: 200px;
}

.lottie-animation {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
