/* Footer Styling */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background: #f31212;
  color: white;
  border-width: thick 0px 0px 0px;
  border-style: solid;
  border-color: black;
  position: relative;
  flex-wrap: wrap;
}

.footer__left,
.footer__right {
  font-size: larger;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__left {
  flex: 1;
  padding-right: 20px;
}

.footer__right {
  flex: 1;
  align-items: flex-end;
  padding-left: 20px;
}

.footer__center {
  flex: 0 1 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer__logo {
  max-width: 150px;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 50%;
  padding: 10px;
  background: #fff;
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.4);
}

.footer__logo:hover {
  transform: scale(1.1);
}

/* Social Links Styling */
.footer__social-icons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 60px;
  z-index: 2;
}

.footer__social-link {
  color: white;
  font-size: 2.2em;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Hover Colors for Social Platforms */
.footer__social-link.facebook:hover {
  color: #4267B2; /* Facebook Blue */
}

.footer__social-link.twitter:hover {
  color: #1DA1F2; /* Twitter Blue */
}

.footer__social-link.instagram:hover {
  color: #C13584; /* Instagram Gradient Pink */
  transform: scale(1.3);
}

/* Contact Info Styling */
.footer__contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px; /* Space between items */
  font-family: "Inter", sans-serif; /* Use Inter font */
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.footer__contact-icon {
  font-size: 1.5em;
}

.footer__license {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  font-size: 0.9em;
  color: white; /* Muted color */
  margin-top: 10px;
  font-family: "Inter", sans-serif; /* Use Inter font */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer__left,
  .footer__right {
    align-items: center;
    text-align: center;
    padding: 10px 0;
  }

  .footer__social-icons {
    justify-content: center;
    margin-bottom: 30px;
  }

  .footer__license {
    text-align: center; /* Center align license text for smaller screens */
  }
}
