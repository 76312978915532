.plumbingPage {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
}

.carouselContainer {
  position: relative;
  height: 400px;
  overflow: hidden;
  color: white;
}

.carouselImage {
  position: relative;
}

.carouselContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.heroTitle {
  font-size: 2.5rem;
  font-weight: bold;
}

.heroSubtitle {
  font-size: 1.2rem;
  margin-top: 10px;
}

/* Services Section */
.servicesSection {
  background: linear-gradient(to left, #0000ff, #1e0056);
  padding: 60px 20px;
}

.serviceCategory {
  margin-bottom: 40px;
}

.serviceCategory h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.serviceCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  color: white;
}

.serviceCard {
  background-color: rgb(243, 18, 18);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.serviceCard strong {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.additionalServices .serviceCards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.additionalServices .serviceCard {
  flex-basis: 40%;
}

/* Contact Section */
.contactSection {
  background-color: #1e3a8a;
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  margin-top: 50px;
}

.bookingLink {
  display: inline-block;
  background-color: #f31212;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 20px;
}

/* Responsive Adjustments */

/* Mobile Layout: Stack Services in a Column */
@media (max-width: 768px) {
  .serviceCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .serviceCard {
    width: 90%;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .heroTitle {
    font-size: 1.8rem;
  }

  .heroSubtitle {
    font-size: 1rem;
  }

  .contactSection {
    padding: 20px;
  }

  .bookingLink {
    font-size: 1rem;
    padding: 10px 15px;
  }
}
