/* Gallery Section Styling */
.gallery-section {
  padding: 80px 20px;
  max-width: 1200px;
  margin: 60px auto;
  text-align: center;
  background: #f31212;
  border: solid black;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.gallery-title {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.gallery-title:after {
  content: '';
  width: 100px;
  height: 4px;
  background: white;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Slick carousel image styling */
.gallery-slide {
  padding: 15px 30px;
}

.gallery-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 2px solid white;
}

/* Hover Effect */
.gallery-image:hover {
  transform: scale(1.08);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

/* Slick carousel navigation dots */
.slick-dots li button:before {
  font-size: 12px;
  color: #0000ff;
}

.slick-dots li.slick-active button:before {
  color: #00125a;
}

/* Slick arrows customization */
.slick-prev:before,
.slick-next:before {
  color: #f31212;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .gallery-section {
    max-width: 90%;
    padding: 40px 10px;
  }

  .gallery-slide {
    padding: 0;
  }

  .gallery-image {
    height: 300px;
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }

  .gallery-title {
    font-size: 2.5rem;
  }

  .gallery-title:after {
    width: 80px;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .gallery-section {
    max-width: 95%;
    padding: 30px 5px;
  }

  .gallery-image {
    height: 250px;
    max-width: 75%;
    margin: 0 auto;
  }
}
