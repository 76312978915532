/* General Styles */
body {
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.landing-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box; /* Include padding in width calculations */
}

/* Video and Services Container */
.video-and-services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  background-color: rgb(228, 222, 222);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  gap: 20px;
  box-sizing: border-box; /* Prevent padding overflow */
}

.video-wrapper {
  width: 100%; /* Full width of container */
  position: relative;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  height: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensure iframe scales */
  height: 100%;
  border: none;
  border-radius: 10px;
}

/* Services Section */
.services-section-horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1250px;
  padding: 20px;
  background: linear-gradient(to right, #00125a, #00005c);
  border: solid #f31212;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  margin: 0 auto; /* Center align */
}

.service-card {
  background: #f31212; /* Bold red background */
  border-radius: 10px;
  box-shadow: 8px 5px 5px rgba(255, 255, 255, 0.5); /* Original shadow */
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: calc(25% - 20px); /* 4 cards per row with gap */
  min-width: 220px;
  max-width: 300px;
  box-sizing: border-box;
}

.service-card:hover {
  transform: scale(1.05); /* Original hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Shadow on hover */
}

.service-content {
  color: white; /* Ensure text inside the card is white */
  font-family: Arial, sans-serif;
}

.service-content h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-content p {
  font-size: 1rem;
  line-height: 1.5;
}

.service-icon {
  color: white;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.service-link {
  text-decoration: none; /* Remove underline for links in cards */
  color: inherit; /* Keep text color as it is */
  display: block; /* Ensure the entire card is clickable */
}

.book-now-button {
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  background-color: rgba(41, 29, 143, 1); /* Restore the blue button */
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-top: 10px;
}

.book-now-button:hover {
  background-color: #190d85; /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}

/* About Us Section */
.about-us-container {
  width: 100%;
  max-width: 1200px;
  background: linear-gradient(to left, #0000ff, #1e0056);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Prevent padding overflow */
  margin: 0 auto; /* Center align */
}

.about-us-header {
  text-align: center;
  background-color: rgb(243, 18, 18);
  color: white;
  font-size: 2.5rem;
  padding: 5px 20px;
  border-radius: 50px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.about-us-image {
  width: 400px;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.about-us-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-us-message {
  background-color: #f31212;
  color: white;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  flex: 1;
  font-size: 1.2rem;
  line-height: 1.6;
}

.about-us-message strong {
  display: block;
  font-size: 1.4rem;
  margin-top: 10px;
}

/* Map Section */
.map-container {
  position: relative;
  width: 100%;
  max-width: 1200px; /* Constrain size on large screens */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto; /* Center align */
  padding: 0;
  box-sizing: border-box; /* Prevent overflow */
}

.google-map {
  width: 100%; /* Ensure the image scales with the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Prevent distortion */
  border-radius: 10px;
}

/* Text Overlay */
.text-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: rgba(243, 18, 18, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Text Boxes Section */
.text-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.side-text {
  flex: 1;
  text-align: center;
  color: white;
  padding: 30px;
  background: linear-gradient(to right, #0051ff, #1e0056);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.bottom-right-overlay {
  text-align: center;
  margin: 20px auto;
  background-color: rgba(243, 18, 18, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  max-width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-section-horizontal {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .service-card {
    width: 90%;
    margin: 0 auto;
  }

  .about-us-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .about-us-image {
    width: 300px;
  }

  .about-us-message {
    font-size: 1rem;
    padding: 15px;
  }

  .map-container {
    max-width: 100%; /* Ensure container does not overflow */
    margin: 0 auto;
    padding: 0;
  }

  .google-map {
    width: 100%; /* Scale image properly within container */
    height: auto;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .text-boxes {
    flex-direction: column;
    gap: 15px;
  }

  .side-text {
    width: 90%;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .about-us-image {
    width: 200px;
  }

  .about-us-message {
    font-size: 0.9rem;
    padding: 10px;
  }

  .service-card {
    padding: 10px;
    width: 100%;
  }

  .side-text {
    font-size: 0.9rem;
    padding: 15px;
  }

  .map-container {
    max-width: 95%; /* Slight margin for extra spacing on very small screens */
    margin: 0 auto;
  }

  .google-map {
    width: 100%; /* Ensure the image fits perfectly within container */
    height: auto;
  }
}
